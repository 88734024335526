<template>
  <div class="meta-list-selector action">
    <div class="action-btn" tabindex="1" @click="checkFilterMenuVisible=!checkFilterMenuVisible"
         @blur="checkFilterMenuVisible=false">
      <div class="checkbox"
           :class="{'all-checked':checkedState == CHECKED_STATUS.ALL,'any-checked':checkedState == CHECKED_STATUS.ANY}"
           @click.capture.stop="handleCheckBoxClicked" ref="checkbox"><span></span></div>
      <i class="el-icon-caret-bottom" style="color: gray"></i>
      <div v-show="checkFilterMenuVisible" class="action-btn-menu">
        <div class="menu-item" @click="checkAll">全选</div>
        <div class="menu-item" @click="cancelAll">全不选</div>
        <slot name="selection"></slot>
      </div>
    </div>

    <div class="action-btn" tabindex="2" @click="actionMenuVisible=!actionMenuVisible"
         @blur="actionMenuVisible=false" v-show="list.some(item => item.checked) && showOperationActionBtn">
      <span style="margin-right: 10px" >操作</span>
      <i class="el-icon-caret-bottom" style="color: gray"></i>
      <div v-show="actionMenuVisible" class="action-btn-menu">
        <slot name="operation">
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import {colorLog} from "../../util";
const CHECKED_STATUS = {
  NONE:1,
  ALL:2,
  ANY:3
}

export default {
  name: "MetaListSelector",
  props:{
    list:Object,
    showOperationActionBtn:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return {
      checkFilterMenuVisible:false,
      actionMenuVisible:false,
      checkedState:CHECKED_STATUS.NONE,
      CHECKED_STATUS
    }
  },
  watch:{
    list:{
      deep:true,
      handler(newVal){
        let allChecked = newVal.every(item => item.checked);
        let someChecked = newVal.some(item => item.checked);
        if(allChecked){
          this.checkedState = CHECKED_STATUS.ALL;
        }else if(someChecked){
          this.checkedState = CHECKED_STATUS.ANY;
        } else{
          this.checkedState = CHECKED_STATUS.NONE;
        }
      }
    }
  },
  methods:{
    handleCheckBoxClicked(e){
      if(this.checkedState !== CHECKED_STATUS.NONE){
        this.cancelAll();
      }else{
        this.checkAll();
      }

    },
    checkAll(){
      this.list.forEach((item)=>{
        item.checked=true;
      });
      this.$emit('changed');
    },
    cancelAll(){
      this.list.forEach((item)=>{
        item.checked=false;
      });
      this.$emit('changed');
    }
  }
}
</script>

<style scoped>
.meta-list-selector{
  width: 100%;
  height: 100%;
}
.checkbox{
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
  height: 12px;
  width: 12px;
  background: white;
  border: solid 1px #dcdfe6;
  line-height: 8px;
  text-align: center;
}


.checkbox span{
  width: 10px;
  height: 2px;
  background: white;
  display: inline-block;
  vertical-align: middle;

}

.all-checked span,.any-checked span{

  background: var(--lightBlue);
}
.all-checked{
  background: var(--lightBlue);
  border-color:var(--lightBlue);
}

.action-btn-menu {
  width: 120px;
  z-index: 9999;
  position: absolute;
  top: 30px;
  background: white;
  left: 0;
  box-shadow: rgb(60 64 67 / 15%) 0 1px 3px 1px;
}
</style>
